.arrow_box{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 112px;
    height: 90px;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08);
    border-radius: 10%;
    justify-content: center;
    align-items: center;
}
.good{
    align-self: flex-start;
    padding-left: 12px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    /* identical to box height, or 150% */
    text-align: center;

    color: #6B7A99;
}
.score{
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    padding-top: 6px;
    font-size: 28px;
    line-height: 28px;
    /* identical to box height, or 127% */
    text-align: center;

    /* Light / Black */
    color: #11263C;
}
.man{
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    /* identical to box height, or 150% */
    text-align: center;

    color:  #A8B1C5;
}